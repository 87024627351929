<template>
  <div>
    <Layout>
      <div
        class="upload__file d-flex justify-content-center gap-3 align-items-center pb-3"
      >
        <div class="text-center">
          <label for=""> CSV Upload</label>
          <div class="mb-2">
            <div class="file-input">
              <input
                type="file"
                name="file-input"
                id="file-input"
                ref="file"
                @change="upload__file()"
                class="file-input__input"
              />

              <label class="file-input__label mb-0" for="file-input">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="upload"
                  class="svg-inline--fa fa-upload fa-w-16"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
                  ></path>
                </svg>
                <span>Upload file</span></label
              >
            </div>
            <span
              style="color: red; font-weight: 500"
              v-if="$v.upload?.file.$error && !filename"
            >
              {{ $v.upload.file.$errors[0].$message }}
            </span>
            {{ filename }}
          </div>

          <button type="button" @click="upload_f()" class="btn btn-primary">
            Submit
          </button>
        </div>
      </div>

      <PageHeader :title="title" :items="items" />

      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 mx-auto">
          <div class="add_coin_form_box mb-3">
            <div class="row middle-row align-items-center">
              <div class="col-md-12">
                <div class="form-box">
                  <!-- ========== New FORM Box ======== -->

                  <form @submit.prevent="createRound()" class="row">
                    <div class="col-md-6">
                      <div class="form__box">
                        <label for="basic-url" class="form-label">Round</label>
                        <div class="input-group mb-3">
                          <input
                            v-model.trim="form.round"
                            type="text"
                            class="form-control"
                          />
                        </div>
                        <span
                          style="color: red; font-weight: 500"
                          v-if="submitted && $v.form.round.$error"
                        >
                          {{ $v.form.round.$errors[0].$message }}
                        </span>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form__box">
                        <label for="basic-url" class="form-label">price</label>
                        <div class="input-group mb-3">
                          <input
                            v-model.trim="form.price"
                            type="text"
                            class="form-control"
                          />
                        </div>
                        <span
                          style="color: red; font-weight: 500"
                          v-if="submitted && $v.form.price.$error"
                        >
                          {{ $v.form.price.$errors[0].$message }}
                        </span>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form__box">
                        <label for="basic-url" class="form-label"
                          >Start Date</label
                        >
                        <div class="input-group mb-3">
                          <input
                            v-model.trim="form.start"
                            type="datetime-local"
                            class="form-control"
                          />
                        </div>
                        <span
                          style="color: red; font-weight: 500"
                          v-if="submitted && $v.form.start.$error"
                        >
                          {{ $v.form.start.$errors[0].$message }}
                        </span>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form__box">
                        <label for="basic-url" class="form-label"
                          >End Date</label
                        >
                        <div class="input-group mb-3">
                          <input
                            v-model.trim="form.end"
                            type="datetime-local"
                            class="form-control"
                          />
                        </div>
                        <span
                          style="color: red; font-weight: 500"
                          v-if="submitted && $v.form.end.$error"
                        >
                          {{ $v.form.end.$errors[0].$message }}
                        </span>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form__box">
                        <label for="basic-url" class="form-label">Limit</label>
                        <div class="input-group mb-3">
                          <input
                            v-model.trim="form.limit"
                            type="number"
                            class="form-control"
                          />
                        </div>
                        <span
                          style="color: red; font-weight: 500"
                          v-if="submitted && $v.form.limit.$error"
                        >
                          {{ $v.form.limit.$errors[0].$message }}
                        </span>
                      </div>
                    </div>

                    <div class="col-12 mt-5">
                      <div class="form-group btn-submit">
                        <div
                          class="spinner-border"
                          role="status"
                          v-if="loading"
                        >
                          <span class="visually-hidden">Loading...</span>
                        </div>
                        <button
                          type="submit"
                          class="btn btn-primary"
                          id="submit"
                          v-if="!loading"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <hr />

            <!-- Button trigger modal -->

            <div class="table-responsive">
              <table class="table table-bordered text-center">
                <thead class="text-nowrap">
                  <tr>
                    <th scope="col">Round</th>
                    <th scope="col">Price</th>
                    <th scope="col">Start</th>
                    <th scope="col">End</th>
                    <th scope="col">Limit</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody
                  v-if="roundData.length != 0"
                  class="table-group-divider text-nowrap"
                >
                  <tr v-for="(round, i) in roundData" :key="i">
                    <td>{{ round.round }}</td>
                    <td>{{ round.price }}</td>
                    <td>{{ getCustomUtcDate(round.start) }}</td>
                    <td>{{ getCustomUtcDate(round.end) }}</td>
                    <td>{{ round.limit }}</td>
                    <td @click="(display = true), editSetData(round)">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          style="fill: red"
                          height="1em"
                          viewBox="0 0 512 512"
                        >
                          <path
                            d="M441 58.9L453.1 71c9.4 9.4 9.4 24.6 0 33.9L424 134.1 377.9 88 407 58.9c9.4-9.4 24.6-9.4 33.9 0zM209.8 256.2L344 121.9 390.1 168 255.8 302.2c-2.9 2.9-6.5 5-10.4 6.1l-58.5 16.7 16.7-58.5c1.1-3.9 3.2-7.5 6.1-10.4zM373.1 25L175.8 222.2c-8.7 8.7-15 19.4-18.3 31.1l-28.6 100c-2.4 8.4-.1 17.4 6.1 23.6s15.2 8.5 23.6 6.1l100-28.6c11.8-3.4 22.5-9.7 31.1-18.3L487 138.9c28.1-28.1 28.1-73.7 0-101.8L474.9 25C446.8-3.1 401.2-3.1 373.1 25zM88 64C39.4 64 0 103.4 0 152V424c0 48.6 39.4 88 88 88H360c48.6 0 88-39.4 88-88V312c0-13.3-10.7-24-24-24s-24 10.7-24 24V424c0 22.1-17.9 40-40 40H88c-22.1 0-40-17.9-40-40V152c0-22.1 17.9-40 40-40H200c13.3 0 24-10.7 24-24s-10.7-24-24-24H88z"
                          />
                        </svg>
                      </span>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else class="table-group-divider text-nowrap">
                  <tr   class="text-centered">
                    <td colspan="6">No data Found</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
    <Dialog header="Edit" :visible.sync="display">
      <div class="edit__modal">
        <form @submit.prevent="editRound()" class="row">
          <div class="col-md-6">
            <div class="form__box">
              <label for="basic-url" class="form-label">Round</label>
              <div class="input-group mb-3">
                <input
                  v-model.trim="edit.round"
                  type="text"
                  class="form-control"
                />
              </div>
              <span
                style="color: red; font-weight: 500"
                v-if="submitted && $v.edit.round.$error"
              >
                {{ $v.edit.round.$errors[0].$message }}
              </span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form__box">
              <label for="basic-url" class="form-label">price</label>
              <div class="input-group mb-3">
                <input
                  v-model.trim="edit.price"
                  type="text"
                  class="form-control"
                />
              </div>
              <span
                style="color: red; font-weight: 500"
                v-if="submitted && $v.edit.price.$error"
              >
                {{ $v.edit.price.$errors[0].$message }}
              </span>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form__box">
              <label for="basic-url" class="form-label">Start Date</label>
              <div class="input-group mb-3">
                <input
                  v-model.trim="edit.start"
                  type="datetime-local"
                  class="form-control"
                />
              </div>
              <span
                style="color: red; font-weight: 500"
                v-if="submitted && $v.edit.start.$error"
              >
                {{ $v.edit.start.$errors[0].$message }}
              </span>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form__box">
              <label for="basic-url" class="form-label">End Date</label>
              <div class="input-group mb-3">
                <input
                  v-model.trim="edit.end"
                  type="datetime-local"
                  class="form-control"
                />
              </div>
              <span
                style="color: red; font-weight: 500"
                v-if="submitted && $v.edit.end.$error"
              >
                {{ $v.edit.end.$errors[0].$message }}
              </span>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form__box">
              <label for="basic-url" class="form-label">Limit</label>
              <div class="input-group mb-3">
                <input
                  v-model.trim="edit.limit"
                  type="number"
                  class="form-control"
                />
              </div>
              <span
                style="color: red; font-weight: 500"
                v-if="submitted && $v.edit.limit.$error"
              >
                {{ $v.edit.limit.$errors[0].$message }}
              </span>
            </div>
          </div>

          <div class="col-12 mt-5">
            <div class="form-group btn-submit">
              <div class="spinner-border" role="status" v-if="loading">
                <span class="visually-hidden">Loading...</span>
              </div>
              <button
                type="submit"
                class="btn btn-primary"
                id="submit"
                v-if="!loading"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </Dialog>
  </div>
</template>

<script>
import Dialog from "primevue/dialog";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
// import Multiselect from "vue-multiselect";
// import { required } from "vuelidate/lib/validators";
import { required} from "@vuelidate/validators";
// import useVuelidate from "@vuelidate/core";
import Swal from "sweetalert2";
import ApiClass from "../../api/api";

// const checkDate = function (dt1, dt2) {
//   return new Date(dt1).getTime() < new Date(dt2).getTime();
// };
// import _ from "lodash";

export default {
  page: {
    title: "Create Round",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  components: {
    Layout,
    PageHeader,
    Dialog,
    // Multiselect,
  },
  setup() {
    return {
      // $v: useVuelidate(),
    };
  },
  data() {
    return {
      display: false,
      title: "Create Rounds",
      loading: false,

      submitted: false,
      items: [
        {
          text: "Rounds",
          href: "#",
        },
        {
          text: "Add Rounds",
          active: true,
        },
      ],
      roundData: [],

      form: {
        round: "",
        price: "",
        start: "",
        end: "",
        limit: "",
      },
      edit_id: null,
      edit: {
        round: "",
        price: "",
        start: "",
        end: "",
        limit: "",
      },
      upload:{
        file:null,
      },
      filename:null
    };
  },
  validations: {
      form: {
        round: {
          required
        },
        price: {
          required,
          

        },
        start: {
          required
        }, // "required",
        end: {
          required
        },
        limit: {
          required
      },
      edit: {
        round: {
          required},
        price: {
          required

        },
        start: {
          required
        }, // "required",
        end: {
          required
        }, // "required",
        limit: {
          required
        }, // "required|min:1",
      },
      upload:{
        file: {required}
      }
      }

  },
  mounted() {
    this.getRounds();
  },
  methods: {
    getCustomUtcDate(fulldate = null){
        // console.log({ fulldate })

        let date = fulldate ? new Date(parseFloat(fulldate)) : new Date();
        // console.log({ date })

        let day = date.getUTCDate();
        let month = date.getUTCMonth() + 1;
        let year = date.getUTCFullYear();
        let hour = date.getUTCHours();
        let min = date.getUTCMinutes();
        let sec = date.getUTCSeconds()

        return `${year}-${month}-${day} ${hour}:${min}:${sec}`;
    },
    async getRounds() {
      let res = await ApiClass.getNodeRequest("admin/ico/get-rounds", true);
      let response = res.data;

      this.roundData = response.data.data;
      console.log({ kdkdd: response });
    },
    editSetData(data){
        this.edit_id =  data.id;
        this.edit= {
           round: data.round,
          price: data.price,
          start: new Date(data.start),
          end: new Date(data.end),
          limit: data.limit,
        }
    },
    upload__file(){

      if(this.$refs.file.files[0].type== "text/csv"){
      this.filename = this.$refs.file.files[0].name
      this.upload.file =this.$refs.file.files[0]
      }
    },

    async upload_f(){


      let form_data = new FormData();
      form_data.append("file", this.upload.file);

      // let validate  = await this.$v.upload.$validate();
      // if (!validate) return;

      let res  = await ApiClass.postNodeRequest('admin/users/airdrop' , true, form_data);
      let response = res.data ;
       if (response.status_code == "1") {
         Swal.fire({
          position: "center",
          icon: "success",
          title: response.message,
          showConfirmButton: true,
          timer: 5000,
        });

      }else{

         Swal.fire({
          position: "center",
          icon: "error",
          title: response.message,
          showConfirmButton: true,
          timer: 5000,
        });
      }
      this.upload.file  = null;
      this.filename = null
        // this.$v.upload.$reset();
      this.$refs.file.value= null;

    },

    async editRound() {
      this.submitted = true;
      let validate = await this.$v.edit.$validate();

      if (!validate) return;

      let edit = {
        id: this.edit_id,
        updatedObj: {
          round: this.edit.round,
          price: this.edit.price,
          start: new Date(this.edit.start).getTime(),
          end: new Date(this.edit.end).getTime(),
          limit: this.edit.limit,
        },
      };

      let res = await ApiClass.postNodeRequest(
        "admin/ico/updateIco",
        true,
        edit
      );
      let response = res.data;

      if (response.status_code == "1") {
         Swal.fire({
          position: "center",
          icon: "success",
          title: response.message,
          showConfirmButton: true,
          timer: 5000,
        });
         await this.getRounds()

      }else{

         Swal.fire({
          position: "center",
          icon: "error",
          title: response.message,
          showConfirmButton: true,
          timer: 5000,
        });
      }
      this.edit = {
                    round: "",
                    price: "",
                    start: "",
                    end: "",
                    limit: "",
                  }
     
      this.$v.edit.$reset();

    },
    async createRound() {
      this.submitted = true;
      // let validate = await this.$v.form.$validate();

      // if (!validate) return;

      let form = {
        round: this.form.round,
        price: this.form.price,
        start: new Date(this.form.start).getTime(),
        end: new Date(this.form.end).getTime(),
        limit: this.form.limit,
      };

      let res = await ApiClass.postNodeRequest(
        "admin/ico/createIco",
        true,
        form
      );
      let response = res.data;
      console.log({response})
      if (response.message == "Success") {
        this.loading = false;
        Swal.fire({
          position: "center",
          icon: "success",
          title: response.message,
          showConfirmButton: true,
          timer: 5000,
        });

        this.form = {
          round: "",
          price: "",
          start: "",
          end: "",
          limit: "",
        };
        // this.$v.form.$reset();
      } else {
        this.loading = false;
        Swal.fire({
          position: "center",
          icon: "error",
          title: response.message,
          showConfirmButton: true,
          timer: 5000,
        });
      }
         await this.getRounds()

    },
  },
};
</script>

<style scoped>
.file-input__input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-input__label {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  font-size: 14px;
  padding: 10px 12px;
  background-color: #556ee6;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
}

.file-input__label svg {
  height: 16px;
  margin-right: 4px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.add_coin_form_box {
  background-color: #fff;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
  border-radius: 5px;
  padding: 15px 15px;
}

.form-group.btn-submit {
  text-align: center;
}

.form-group .currency-image {
  text-align: center;
  max-width: 44px;
  height: 44px;
  margin: 0 auto;
  border-radius: 26px;
  padding: 0;
  margin-top: 18px;
  margin-left: 0;
}

.form-group .currency-image img {
  max-width: 39px;
  margin-top: 5px;
}

.border_box {
  border: 1px solid #ebecf1;
  border-radius: 5px;
}
</style>
